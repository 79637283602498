<template>
  <!-- 公共页面 -->
  <div class="public">
    <div class="publicLeft">
      <div class="leftTitle">
        <img class="leftTitleImg" src="../../assets/logo.png" />
        <div class="leftTitleText">联通健康通信息服务平台</div>
      </div>
      <el-menu :default-active="menuIndex" class="el-menu-vertical-demo" router>
        <el-submenu index="1">
          <template slot="title">
            <i class="iconfont icon-danwei1"></i>
            <span>人员来访</span>
          </template>
          <el-menu-item index="/content/home">数据看板 </el-menu-item>
          <el-menu-item index="/content/record">访客记录</el-menu-item>
          <el-menu-item
            v-if="isDw === 1 || isDw === 4"
            index="/content/company-admin"
            >场所管理</el-menu-item
          >
          <el-menu-item
            v-if="isDw === 1 || isDw === 4"
            index="/content/gatekeeper-admin"
            >点位管理</el-menu-item
          >
          <!-- <el-menu-item
                        v-if="isDw === 1 || isDw === 4"
                        index="/content/personnel-admin">本地关注人员管理</el-menu-item>
                    <el-menu-item
                        v-if="isDw === 1 || isDw === 4"
                        index="/content/classification-admin">本地关注人员分类管理</el-menu-item> -->
        </el-submenu>
        <el-submenu v-if="isDw === 2" index="2">
          <template slot="title">
            <i class="iconfont icon-menjin"></i>
            <span>考勤管理</span>
          </template>
          <el-menu-item index="/content/check-record">考勤记录</el-menu-item>
          <el-menu-item index="/content/department-admin"
            >组织管理</el-menu-item
          >
          <el-menu-item index="/content/staff-admin">员工管理</el-menu-item>
          <el-menu-item
            v-if="isNeedAttendance === 2 || isNeedAttendance === 3"
            index="/content/visitor-admin"
            >访客管理</el-menu-item
          >
          <el-menu-item index="/content/equipment-record"
            >设备下发记录</el-menu-item
          >
        </el-submenu>
        <el-submenu v-if="isDw === 1" index="3">
          <template slot="title">
            <i class="iconfont icon-menjin"></i>
            <span>内部管理</span>
          </template>
          <el-menu-item index="/content/equipment-admin">设备管理</el-menu-item>
          <el-menu-item
            v-if="isGiveAccount"
            index="/content/commuting-equipment"
            >通勤设备管理</el-menu-item
          >
          <el-menu-item index="/content/visit-state">数据统计</el-menu-item>
          <el-menu-item index="/content/account-admin">账号管理</el-menu-item>
          <!-- <el-menu-item index="/content/agent-admin">代理商管理</el-menu-item> -->
          <!-- <el-menu-item index="/content/requestLog">请求日志</el-menu-item> -->
          <el-menu-item index="/content/model-admin">设备型号管理</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <div class="content">
      <!-- 顶部 -->
      <div class="contentTop">
        <div class="contentTopAccount">
          <img class="accountImg" src="../../assets/avatar.png" />
          <div class="accountText">{{ accountName }}</div>
          <!-- 选项 -->
          <div class="myDataSty">
            <div
              @click="myDataClick(index)"
              class="myDataText"
              v-for="(myData, index) in myDatas"
              :key="index"
            >
              {{ myData }}
            </div>
          </div>
        </div>
      </div>
      <div class="contentTopTitle">{{ titleName }}</div>
      <router-view class="contentCon" />
    </div>
  </div>
</template>
<script>
export default {
  name: "public",
  props: {},
  data() {
    return {
      myDatas: ["修改密码", "退出登录"],
      // 内容标题
      titleName: "场所管理",
      // 账号
      accountName: "",
      // 被选中导航栏
      menuIndex: "/content/home",
      // 是否显示 内部企业/组织管理
      isDw: null,
      // 考勤管理 是否需要
      isNeedAttendance: null,
    };
  },
  watch: {
    // 监听页面路由-使刷新后默认选中页面与路由显示页面一致
    $route: {
      handler(newVal) {
        // console.log('newVal: ', newVal)
        if (newVal.name !== "home") {
          let setInterval1 = sessionStorage.getItem("setInterval1");
          let setInterval2 = sessionStorage.getItem("setInterval2");
          clearInterval(setInterval1);
          clearInterval(setInterval2);
        }
        // 页面跳转
        if (newVal.name === "home") {
          this.titleName = "数据看板";
        }
        if (newVal.name === "company-admin") {
          this.titleName = "场所管理";
        }
        if (newVal.name === "record") {
          this.titleName = "访客记录";
        }
        if (newVal.name === "check-record") {
          this.titleName = "考勤记录";
        }
        if (newVal.name === "department-admin") {
          this.titleName = "组织管理";
        }
        if (newVal.name === "staff-admin") {
          this.titleName = "员工管理";
        }
        if (newVal.name === "visitor-admin") {
          this.titleName = "访客管理";
        }
        if (newVal.name === "equipment-record") {
          this.titleName = "设备下发记录";
        }
        if (newVal.name === "gatekeeper-admin") {
          this.titleName = "点位管理";
        }
        if (newVal.name === "personnel-admin") {
          this.titleName = "本地关注人员管理";
        }
        if (newVal.name === "classification-admin") {
          this.titleName = "本地关注人员分类管理";
        }
        if (newVal.name === "equipment-admin") {
          this.titleName = "设备管理";
        }
        if (newVal.name === "commuting-equipment") {
          this.titleName = "通勤设备管理";
        }
        if (newVal.name === "visit-state") {
          this.titleName = "数据统计";
        }
        if (newVal.name === "account-admin") {
          this.titleName = "账号管理";
        }
        if (newVal.name === "agent-admin") {
          this.titleName = "代理商管理";
        }
        if (newVal.name === "requestLog") {
          this.titleName = "请求日志";
        }
        if (newVal.name === "passageway-admin") {
          this.titleName = "通道管理";
        }
        if (newVal.name === "model-admin") {
          this.titleName = "设备型号管理";
        }
        if (newVal.name === "information") {
          this.titleName = sessionStorage.getItem("informationTitle");
        }
        if (newVal.name === "my-password") {
          this.titleName = "修改密码";
        }
        this.$nextTick(() => {
          this.menuIndex = newVal.path;
        });
      },
      immediate: true,
    },
  },
  created() {
    this.accountName = sessionStorage.getItem("accountName");
    // 不同权限 被访问到的页面
    let userInfo = sessionStorage.getItem("userInfo") + "";
    this.isDw = Number(userInfo);
    // 特定人员
    let isGiveAccount = sessionStorage.getItem("isGiveAccount");
    this.isGiveAccount = Number(isGiveAccount);
    // 添加场所-通行方式 选择2或3显示
    let isNeedAttendance = sessionStorage.getItem("isNeedAttendance");
    this.isNeedAttendance = Number(isNeedAttendance);
  },
  methods: {
    // 点击退出登录
    myDataClick(index) {
      if (index === 1) {
        this.$router.push("/login");
      } else if (index === 0) {
        this.$router.push("/content/my-password");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.public {
  width: 100%;
  height: 100%;
  // 左侧边栏
  .publicLeft {
    width: 240px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    color: #fff;
    z-index: 100;
    // 标题
    .leftTitle {
      line-height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
      background-color: #ff6600;
      .leftTitleImg {
        width: 38px;
        margin-right: 10px;
      }
    }
    & ::v-deep .el-menu {
      border-right: unset;
      .is-active {
        background-color: #ff66001c !important;
      }
      .el-submenu {
        .el-menu-item {
          padding-left: 56px !important;
        }
      }
      .el-menu-item {
        .iconfont {
          margin-right: 20px;
          font-weight: bold;
        }
      }
      .is-active {
        background-color: #ecf5ff;
      }
      // 有子集
      .el-submenu {
        .el-submenu__title {
          background-color: #fff;
        }
        .iconfont {
          width: unset;
          margin-right: 20px;
          font-weight: bold;
        }
      }
    }
  }
  .content {
    width: calc(100% - 240px);
    height: 100%;
    margin-left: 240px;
    background-color: #f0f2f5;
    .contentTop {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #fff;
      background-color: #ff6600;
      padding: 0 40px;
      box-sizing: border-box;
      .contentTopAccount {
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        .accountImg {
          background-color: rgb(215, 215, 215);
          border-radius: 50%;
          width: 24px;
          height: 24px;
          object-fit: cover;
          margin-right: 6px;
        }
        .myDataSty {
          display: none;
          width: 120px;
          position: absolute;
          top: 50px;
          right: 0;
          color: #333;
          background-color: #fff;
          border-radius: 4px;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
          text-align: center;
          z-index: 100;
          .myDataText {
            line-height: 52px;
            cursor: pointer;
          }
          .myDataText:hover {
            color: #ff6600;
          }
        }
      }
      .contentTopAccount:hover {
        .myDataSty {
          display: block;
        }
      }
    }
    .contentTopTitle {
      line-height: 40px;
      border-left: 1px #f0f0f0 solid;
      padding: 0 40px;
      background-color: #fff;
      box-sizing: border-box;
    }
    .contentCon {
      height: calc(100% - 140px);
    }
  }
}
</style>
